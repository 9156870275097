import { eventsClient, eventsClientWithoutError } from "./http";

export const getAllTheEvents = async (tenantId, searchOptions) => {
  if (!tenantId) return null;

  const params = {
    skip: searchOptions.skip,
    take: searchOptions.take,
  };

  searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);
  searchOptions.skipPaging && (params.skipPaging = searchOptions.skipPaging);

  return await eventsClient.get(`/api/tenants/${tenantId}/events`, { params });
};

// searchoptions = {skip, take, searchTerm}
export const getSaleEvents = async (searchOptions) => {
  const tenantId = localStorage.getItem("tenant_id");
  if (!tenantId) return null;

  const params = {
    skip: searchOptions.skip,
    take: searchOptions.take,
    onSaleOnly: true,
    skipPaging: searchOptions.skipPaging,
  };

  searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);
  searchOptions.include && (params.include = searchOptions.include);

  return await eventsClient.get(`/api/tenants/${tenantId}/events`, { params });
};

export const getPastEvents = async (searchOptions) => {
  const tenantId = localStorage.getItem("tenant_id");
  if (!tenantId) return null;

  const params = {
    skip: searchOptions.skip,
    take: searchOptions.take,
    pastEventsOnly: true,
  };

  searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);

  return await eventsClient.get(`/api/tenants/${tenantId}/events`, { params });
};

export const getDraftEvents = async (searchOptions) => {
  const tenantId = localStorage.getItem("tenant_id");
  if (!tenantId) return null;

  const params = {
    skip: searchOptions.skip,
    take: searchOptions.take,
    draftOnly: true,
  };

  searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);

  return await eventsClient.get(`/api/tenants/${tenantId}/events`, { params });
};

export const getAllEvents = async () => {
  const tenantId = localStorage.getItem("tenant_id");
  if (!tenantId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events`);
};

export const getEventDetails = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}`);
};

export const getEventOptions = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/feature-options`);
};

export const getEventPaymentProcessorDetails = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/payment-processors`);
};

export const editPaymentProcessorDetails = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/payment-processors`, data);
};

export const getEventTicketTypes = async (tenantId, eventId, searchOptions) => {
  if (!tenantId || !eventId) return null;

  const params = {};

  searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);
  searchOptions.filterByIds && (params.filterByIds = searchOptions.filterByIds);
  searchOptions.archived && (params.archived = searchOptions.archived);
  searchOptions.include && (params.include = searchOptions.include);

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/ticket-types`, { params });
};

export const getEventTicketTypeStyles = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/ticket-type-styles`);
};

export const getTicketTypeDetails = async (tenantId, eventId, ticketTypeId) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}`);
};

export const getEventTribeSettings = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;

  return await eventsClientWithoutError.get(`/api/tenants/${tenantId}/events/${eventId}/tribe-settings`);
};

export const getEventsDelivery = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/delivery`);
};

export const postDeliveryETicket = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/delivery/etickets`, data);
};

export const postDeliveryWillCall = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/delivery/will-call`, data);
};

export const updateShippingRate = async (tenantId, eventId, optionId, rateId, data) => {
  if (!tenantId || !eventId || !optionId || !rateId) return null;

  return await eventsClient.put(`/api/tenants/${tenantId}/events/${eventId}/delivery/shipping-options/${optionId}/rates/${rateId}`, data);
};

export const updateShippingRateAction = async (tenantId, eventId, optionId, rateId, action) => {
  if (!tenantId || !eventId || !optionId || !rateId || !action) return null;

  return await eventsClient.put(`/api/tenants/${tenantId}/events/${eventId}/delivery/shipping-options/${optionId}/rates/${rateId}/${action}`);
};

export const deletePaidShippingRate = async (tenantId, eventId, optionId, rateId) => {
  if (!tenantId || !eventId || !optionId || !rateId) return null;

  return await eventsClient.delete(`/api/tenants/${tenantId}/events/${eventId}/delivery/paid-shipping-options/${optionId}/rates/${rateId}/delete`);
};

export const updateDeliveryCountrySelections = async (tenantId, eventId, optionId, data) => {
  if (!tenantId || !eventId || !optionId || !data) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/delivery/shipping-options/${optionId}/country-selections`, data);
};

export const addShippingRate = async (tenantId, eventId, optionId, data) => {
  if (!tenantId || !eventId || !optionId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/delivery/shipping-options/${optionId}/rates`, data);
};

export const postDeliveryShippingOptions = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/delivery/shipping-options`, data);
};

export const addEvent = async (tenantId, data) => {
  if (!tenantId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events`, data);
};

export const updateEvent = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.patch(`/api/tenants/${tenantId}/events/${eventId}`, data);
};

export const getEventFAQs = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/faq-items`);
};

export const postEventDescription = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/descriptions`, data);
};

export const getEventDescription = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/descriptions`);
};

export const updateEventDescription = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.patch(`/api/tenants/${tenantId}/events/${eventId}/descriptions`, data);
};

export const getEventPhotos = async (tenantId, eventId, searchOption) => {
  if (!tenantId || !eventId) return null;

  const params = {
    skip: searchOption.skip,
    take: searchOption.take,
  };

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/photos`, params);
};

export const postEventPhotos = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/photos`, data);
};

export const removeEventPhotos = async (tenantId, eventId, photoId) => {
  if (!tenantId || !eventId || !photoId) return null;
  return await eventsClient.delete(`/api/tenants/${tenantId}/events/${eventId}/photos/${photoId}`);
};

export const postNewFAQItem = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/faq-items`, data);
};

export const editFAQItem = async (tenantId, eventId, faqId, data) => {
  if (!tenantId || !eventId || !faqId) return null;

  return await eventsClient.put(`/api/tenants/${tenantId}/events/${eventId}/faq-items/${faqId}`, data);
};

export const deleteFAQItem = async (tenantId, eventId, faqId) => {
  if (!tenantId || !eventId || !faqId) return null;

  return await eventsClient.delete(`/api/tenants/${tenantId}/events/${eventId}/faq-items/${faqId}`);
};

export const addEventTicket = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId || !data) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/ticket-types`, data);
};

export const updateEventTicketType = async (tenantId, eventId, ticketTypeId, data) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;

  return await eventsClient.put(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}`, data);
};

export const addComboTickets = async (tenantId, eventId, ticketTypeId, data) => {
  if (!tenantId || !eventId || !ticketTypeId || !data) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/combo-options`, data);
};

export const removeComboTickets = async (tenantId, eventId, ticketTypeId, itemTicketTypeId) => {
  if (!tenantId || !eventId || !ticketTypeId || !itemTicketTypeId) return null;

  return await eventsClient.delete(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/combo-options/${itemTicketTypeId}`);
};

export const cloneTicketType = async (tenantId, eventId, ticketTypeId) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/clone-ticket-type`);
};

export const reorderTicketTypes = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/reorder-ticket-types`, data);
};

export const getPhotosTicketTypes = async (tenantId, eventId, ticketTypeId) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;
  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/photos`);
};

export const photosTicketTypes = async (tenantId, eventId, ticketTypeId, data) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/photos`, data);
};

export const removePhotosTicketTypes = async (tenantId, eventId, ticketTypeId, photoId) => {
  if (!tenantId || !eventId || !ticketTypeId || !photoId) return null;
  return await eventsClient.delete(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/photos/${photoId}`);
};

export const getTicketBloxPricing = async (tenantId, currencyCode, price) => {
  if (!currencyCode || !price || !tenantId) return null;
  return await eventsClient.get(`/api/utilities/tenants/${tenantId}/ticketblox-fees`, {
    params: {
      currency: currencyCode,
      price: price,
    },
  });
};

export const setNFTConfigurationByTicketType = async (tenantId, eventId, ticketTypeId, data) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/nft-ticket-types/${ticketTypeId}/configuration`, data);
};

export const getNftContractByEvent = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/nft-contracts`);
};

export const createNftContractForEvent = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/nft-contracts`);
};

export const publishNftContractTicketType = async (tenantId, eventId, ticketTypeId) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/nft-ticket-types/${ticketTypeId}/publish`);
};

export const getPasswordCriteriaTicketType = async (tenantId, eventId, ticketTypeId, decrypt) => {
  if (!tenantId || !eventId || !ticketTypeId) return null;

  const params = { decrypt: decrypt };

  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}/password-criteria`, { params });
};

export const getPassportRules = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;
  return await eventsClientWithoutError.get(`/api/tenants/${tenantId}/events/${eventId}/passport-rules`);
};

export const addPassportRules = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId || !data) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/passport-rules`, data);
};

export const updatePassportRules = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId || !data) return null;
  return await eventsClient.patch(`/api/tenants/${tenantId}/events/${eventId}/passport-rules`, data);
};

export const addPassportTicketTypeRule = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId || !data) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/passport-rules/ticket-type-rules`, data);
};

export const updatePassportRulesByRuleId = async (tenantId, eventId, ruleId, data) => {
  if (!tenantId || !eventId || !ruleId || !data) return null;
  return await eventsClient.patch(`/api/tenants/${tenantId}/events/${eventId}/passport-rules/ticket-type-rules/${ruleId}`, data);
};

export const deletePassportRulesByRuleId = async (tenantId, eventId, ruleId) => {
  if (!tenantId || !eventId || !ruleId) return null;
  return await eventsClient.delete(`/api/tenants/${tenantId}/events/${eventId}/passport-rules/ticket-type-rules/${ruleId}`);
};

export const getEventSEOSettings = async (tenantId, eventId) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.get(`/api/tenants/${tenantId}/events/${eventId}/seo-settings`);
};

export const addEventSEOSettings = async (tenantId, eventId, data) => {
  if (!tenantId || !eventId) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/page-seo-settings`, data);
};

export const updateEventStatus = async (tenantId, eventId, action) => {
  if (!tenantId || !eventId || !action) return null;
  return await eventsClient.post(`/api/tenants/${tenantId}/events/${eventId}/${action}`);
};
