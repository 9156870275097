import React, { useState } from "react";
import { copyValue } from "util/helpers";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icons from "components/Common/Icons";
import { snackActions } from "components/Notification";

function CopyComponent(props) {
  const { title, showTitle, titleClassName, valueClassName, showValue, value, onValueClick, iconSize } = props;
  const [isDone, setIsDone] = useState(false);

  return (
    <div className="flex items-center !justify-start md:!justify-end gap-1">
      {showTitle && <span className={classNames("info-header text-uppercase typo-14-500 md:typo-16-500 text-neutrals4", { [titleClassName]: !!titleClassName })}>{title}:</span>}
      <span className={"flex items-center info-detail typo-14-500 md:typo-16-500 gap-2"}>
        {showValue && (
          <div className={classNames({ [valueClassName]: !!valueClassName })} onClick={() => onValueClick && onValueClick()}>
            {value}
          </div>
        )}
        <Icons
          type={isDone ? "check" : "copy"}
          className="text-green cursor-pointer"
          size={iconSize}
          onClick={() => {
            copyValue(value);
            setIsDone(true);
            snackActions.success(`"${value}" Copied`);
            setTimeout(() => {
              setIsDone(false);
            }, 1000);
          }}
        />
      </span>
    </div>
  );
}

export default CopyComponent;

CopyComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showTitle: PropTypes.bool,
  titleClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showValue: PropTypes.bool,
  valueClassName: PropTypes.string,
  onValueClick: PropTypes.func,
  iconSize: PropTypes.string,
};

CopyComponent.defaultProps = {
  title: "",
  showTitle: true,
  titleClassName: "",
  value: "",
  showValue: true,
  valueClassName: "",
  onValueClick: () => {},
  iconSize: "xxs",
};
