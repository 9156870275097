import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import MasterButton from "components/Common/MasterButton";
import Icons from "components/Common/Icons";
import styles from "./SideDrawer.module.scss";
import IntlMessages from "util/IntlMessages";
import classNames from "classnames";

function SideDrawer(props) {
  const {
    outerContainerClassName,
    outerClassName,
    containerClassName,
    isOpen,
    onClose,
    showCloseBtn,
    children,
    title,
    description,
    actions,
    isFullScreen,
    isDisableOutsideClick,
    customElement,
    showHeader,
    isShowLayout,
    isLeftSideLayout,
    // comment
  } = props;

  useEffect(() => {
    if (isOpen) {
      const target = document.querySelector("#side-drawer");
      disableBodyScroll(target, {
        allowTouchMove: (el) => el.tagName === "DIV" || "INPUT" || "SVG" || "IMG" || "BUTTON",
      });
      return () => clearAllBodyScrollLocks();
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isOpen]);

  function handleClose() {
    onClose();
    clearAllBodyScrollLocks();
  }

  if (typeof document !== "undefined") {
    return createPortal(
      isOpen && (
        <div
          className={cn(styles.modal, outerContainerClassName, {
            "p-0": !isShowLayout,
            "justify-content-start": isLeftSideLayout,
          })}
          id="side-drawer"
        >
          <div className={cn(isFullScreen ? styles.outerSlidable : styles.outer, outerClassName, { "rounded-0": !isShowLayout })}>
            <OutsideClickHandler onOutsideClick={isDisableOutsideClick ? () => {} : () => handleClose()}>
              <div className={cn(styles.container, styles["hide-scrollbar"], containerClassName, { "p-0 h-screen": !isShowLayout, "h-[calc(100dvh-2rem)]": isShowLayout })}>
                {showHeader && (
                  <div className={styles.header}>
                    {title && <div className={cn("typo-18-700 md:typo-20-700 lg:typo-22-700", styles.title)}>{title}</div>}
                    {description && <div className="typo-12-500 md:typo-13-500">{description}</div>}
                    {customElement}
                  </div>
                )}
                <div className={cn(styles.childContainer, styles["hide-scrollbar"], { "px-1": isShowLayout })}>{children}</div>
                <div className={cn("w-full flex", { "pt-2": isShowLayout })}>
                  {actions?.length > 0 && (
                    <div className={styles.actionsList}>
                      {actions?.map((item, index) => {
                        if (item.hide) return null;
                        return (
                          <MasterButton
                            key={`action-button-${index}`}
                            buttonText={item?.title}
                            className={item?.className || ""}
                            isDisabled={item?.disabled}
                            isLoading={item?.isLoading}
                            onClick={item?.onClick}
                            type="button"
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                {showCloseBtn && (
                  <div className={styles.close} onClick={handleClose}>
                    <Icons type="close" size="xl" className={styles.closeIcon} />
                    <div className={classNames(styles.closeText, "hidden md:block")}>
                      <IntlMessages id="button.close_btn" />
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      ),
      document.body
    );
  }
}

export default SideDrawer;

SideDrawer.propTypes = {
  outerContainerClassName: PropTypes.string,
  outerClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseBtn: PropTypes.bool,
  children: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      isLoading: PropTypes.bool,
      title: PropTypes.string,
      disabled: PropTypes.bool,
      className: PropTypes.string,
      onClick: PropTypes.func,
      hide: PropTypes.bool,
    })
  ),
  isFullScreen: PropTypes.bool,
  isDisableOutsideClick: PropTypes.bool,
  customElement: PropTypes.element,
  showHeader: PropTypes.bool,
  isShowLayout: PropTypes.bool,
  isLeftSideLayout: PropTypes.bool,
};

SideDrawer.defaultProps = {
  outerContainerClassName: "",
  outerClassName: "",
  containerClassName: "",
  isOpen: false,
  onClose: () => {},
  showCloseBtn: true,
  children: null,
  title: "",
  description: "",
  actions: [],
  endButtons: [],
  isFullScreen: false,
  isDisableOutsideClick: false,
  customElement: null,
  showHeader: true,
  isShowLayout: true,
  isLeftSideLayout: false,
};
