import React from "react";
import { Link, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import LanguageSelector from "components/LanguageSelector";
import CopyComponent from "components/CopyComponent";
import MasterButton from "components/Common/MasterButton";
import { useSeedContext } from "context/SeedContext";
import { useTenantContext } from "context/TenantContext";
import Icons from "components/Common/Icons";

function Header() {
  const { tenantId, tenantName, eventDomainName, logo } = useTenantContext();
  const { toggleCollapsedNav } = useSeedContext();

  const openEventPage = () => {
    window.open(`https://${eventDomainName}`, "_blank");
  };

  return (
    <div className="app-main-header">
      <div className="flex h-[70px] min-h-[70px] max-h-[70px] text-gray-900 border-b border-solid border-[#ebecf0] px-[1rem] md:px-6">
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-2 items-center">
            <Icons type="menu" className="block lg:hidden" aria-label="Menu" onClick={toggleCollapsedNav} size="5xl" />
            <div className="hidden md:block">
              {logo?.imageUrl ? (
                <img src={logo?.imageUrl} alt={tenantName} title={tenantName} className="h-12 rounded-lg object-cover bg-blankImage" />
              ) : (
                <div className="bg-neutrals7 p-2 rounded-lg typo-18-500">Site Logo</div>
              )}
            </div>
            <div className="flex flex-col">
              <span className="typo-22-600 cursor-pointer" onClick={openEventPage}>
                {tenantName}
              </span>
              {tenantName && (
                <div className="hidden lg:block">
                  <CopyComponent showTitle={false} value={tenantId} valueClassName="typo-14-400 text-neutrals4" />
                </div>
              )}
              {tenantName && (
                <div className="block lg:hidden">
                  <CopyComponent showTitle={false} value={eventDomainName} onValueClick={openEventPage} valueClassName="link-button typo-14-400 text-neutrals4" />
                </div>
              )}
            </div>
          </div>
          <div className="hidden lg:block">
            {tenantName && (
              <div className="btn-outline-drop p-2">
                <CopyComponent title={<IntlMessages id="component.header.linkTitle" />} value={eventDomainName} onValueClick={openEventPage} valueClassName="link-button typo-14-400" />
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="hidden md:flex">
              <LanguageSelector />
            </div>
            <Link to="/tenants-list">
              <MasterButton
                buttonText={
                  <div className="py-2">
                    <IntlMessages id="app.components.header.sites" />
                  </div>
                }
                className="h7-btn blue text-white typo-16-400 px-3 !py-[1px] hover:underline"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
