import { useMemo } from "react";
import { useQuery } from "react-query";
import { getBocaCommandTypes, getBocaFontTypes, getBocaRotateTypes, getDataFields, getQzCertificate, getUserClaims, getUserInfo } from "services-manager/seedManager";
import { getTimezones, getCurrencies, getLocales } from "services-manager/geographyManager";
import { getAssociateTypes } from "services-manager/associatesManager";
import WebhooksServices from "services-manager/webhooksManager";

export function useSeedData(tenantId, userId, isAuthenticated) {
  const isUserAuthorized = isAuthenticated && !!userId;

  const { data: commandTypes } = useQuery([`/api/boca-command-types`], getBocaCommandTypes, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });
  const { data: rotateTypes } = useQuery([`/api/boca-rotate-types`], getBocaRotateTypes, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });
  const { data: fontTypes } = useQuery([`/api/boca-font-types`], getBocaFontTypes, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });
  const { data: dataFields } = useQuery([`/api/dynamic-fields`], getDataFields, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });
  const { data: userProfile } = useQuery([userId, `/api/users/${userId}/profile`], getUserClaims, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });

  const { data: userInfo } = useQuery([userId, `/api/${userId}/userinfo`], getUserInfo, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });

  const { data: qzCertificate } = useQuery([tenantId, `api/tenants/${tenantId}/qz-certificate`], getQzCertificate, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && isUserAuthorized,
    keepPreviousData: true,
  });

  const { data: associateTypes } = useQuery([tenantId, `api/tenants/${tenantId}/associate-types`], getAssociateTypes, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && isUserAuthorized,
    keepPreviousData: true,
  });

  const {
    data: timezonesData,
    isLoading: isTimezonesDataLoading,
    isSuccess: isTimezonesDataSuccess,
  } = useQuery([{ key: `/api/timezones` }], getTimezones, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });

  const {
    data: currenciesData,
    isLoading: isCurrenciesDataLoading,
    isSuccess: isCurrenciesDataSuccess,
  } = useQuery([{ key: `/api/currencies` }], getCurrencies, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });

  const {
    data: localesData,
    isLoading: isLocalesDataLoading,
    isSuccess: isLocalesDataSuccess,
  } = useQuery([{ key: `/api/locales` }], getLocales, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized,
    keepPreviousData: true,
  });

  const {
    data: webhookEventTypes,
    isLoading: isWebhookEventTypesLoading,
    isSuccess: isWebhookEventTypesSuccess,
  } = useQuery([tenantId, `/api/tenants/${tenantId}/webhook-event-types`], WebhooksServices.getWebhooksEventTypes, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && isUserAuthorized,
    keepPreviousData: true,
  });

  const seedData = useMemo(
    () => ({
      commandTypes: commandTypes,
      rotateTypes: rotateTypes,
      fontTypes: fontTypes,
      dataFields: dataFields,
      userProfile: userProfile,
      userInfo: userInfo,
      qzCertificate: qzCertificate,
      associateTypes: associateTypes,
      timezonesData: timezonesData,
      isTimezonesDataLoading: isTimezonesDataLoading,
      isTimezonesDataSuccess: isTimezonesDataSuccess,
      currenciesData: currenciesData,
      isCurrenciesDataLoading: isCurrenciesDataLoading,
      isCurrenciesDataSuccess: isCurrenciesDataSuccess,
      localesData: localesData,
      isLocalesDataLoading: isLocalesDataLoading,
      isLocalesDataSuccess: isLocalesDataSuccess,
      webhookEventTypes: webhookEventTypes,
      isWebhookEventTypesLoading: isWebhookEventTypesLoading,
      isWebhookEventTypesSuccess: isWebhookEventTypesSuccess,
    }),
    [
      commandTypes,
      rotateTypes,
      fontTypes,
      dataFields,
      userProfile,
      userInfo,
      qzCertificate,
      associateTypes,
      timezonesData,
      isTimezonesDataLoading,
      isTimezonesDataSuccess,
      currenciesData,
      isCurrenciesDataLoading,
      isCurrenciesDataSuccess,
      localesData,
      isLocalesDataLoading,
      isLocalesDataSuccess,
      webhookEventTypes,
      isWebhookEventTypesLoading,
      isWebhookEventTypesSuccess,
    ]
  );

  return seedData;
}
