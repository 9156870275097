import React from "react";
import PropTypes from "prop-types";
import SidebarItem from "../SidebarItem";
import classNames from "classnames";
import styles from "./SidebarCategory.module.scss";

function SidebarCategory(props) {
  const { options, handleRedirect, pathname } = props;

  return (
    <div className={classNames(styles.sidebarCategory, styles.optionsWrapper, "max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-103px)]")}>
      {options?.length > 0 && (
        <div className={classNames(styles.sidebarCategory, styles.showOptions, styles.optionsMapping)}>
          {options.map((o, i) => {
            if (o.hide) return null;
            return <SidebarItem key={i} item={o} handleRedirect={handleRedirect} pathname={pathname} />;
          })}
        </div>
      )}
    </div>
  );
}

export default SidebarCategory;

SidebarCategory.propTypes = {
  options: PropTypes.array,
  pathname: PropTypes.string,
  handleRedirect: PropTypes.func,
};

SidebarCategory.defaultProps = {
  options: [],
  pathname: "",
  handleRedirect: () => {},
};
