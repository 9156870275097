import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "util/IntlMessages";
import MasterButton from "components/Common/MasterButton";
import Icons from "components/Common/Icons";
import Avatar from "components/Common/Avatar";

function TenantCard(props) {
  const { tenantDetails, onSelectTenant, allowSelect, allowEdit, onEditClick } = props;
  return (
    <div className="bg-neutrals9 flex md:p-4 items-center justify-between rounded-lg border border-solid border-gray-300 tenantCard h-20">
      <div className="w-full flex items-center justify-start relative md:!p-0 p-3">
        <Avatar src={tenantDetails.logo && tenantDetails.logo.imageUrl} className="!h-[50px] !w-[50px] bg-blankImage rounded-lg border-2">
          {tenantDetails.tenantName[0].toUpperCase()}
        </Avatar>
        <div className="flex flex-col mx-2">
          <span className="typo-16-500 text-wrap">{tenantDetails.tenantName}</span>
          <span className="typo-14-400 text-neutrals4 text-wrap tenantLink z-20 break-all" onClick={() => window.open(`https://${tenantDetails.eventDomainName}`, "_blank")}>
            {tenantDetails.eventDomainName}
          </span>
        </div>
        <div className="w-full h-full absolute left-0 md:hidden" onClick={onSelectTenant} />
      </div>
      {allowSelect && <MasterButton buttonText={<IntlMessages id="button.select" />} onClick={onSelectTenant} className="h7-btn blue h7-btn-outline ml-1 hidden md:block" />}
      {allowEdit && <Icons type="edit" size="xl" className="text-blue cursor-pointer mx-3 lg:!mx-0" onClick={onEditClick} />}
    </div>
  );
}

export default TenantCard;

TenantCard.propTypes = {
  tenantDetails: PropTypes.object.isRequired,
  onSelectTenant: PropTypes.func,
  allowSelect: PropTypes.bool,
  allowEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
};

TenantCard.defaultProps = {
  onSelectTenant: undefined,
  allowSelect: true,
  allowEdit: false,
  onEditClick: undefined,
};
