import React from "react";
import Avatar from "components/Common/Avatar";
import authenticationService from "../../services/AuthenticationService";
import { useAuthContext } from "context/AuthProvider";
import styles from "./UserInfo.module.scss";
import Icons from "components/Common/Icons";
import Humanize from "humanize-plus";
import ActionsMenu from "components/ActionsMenu";
import IntlMessages from "util/IntlMessages";

function UserInfo() {
  const { name: userName } = useAuthContext();

  return (
    <div className={styles.userInfoWrapper}>
      <div className={styles.userInfo}>
        <Avatar className={styles.userAvatar}>{userName?.[0]?.toUpperCase() || ""}</Avatar>
        <div className={styles.name}>{Humanize.truncate(userName || "", 17, "...")}</div>
      </div>
      <ActionsMenu
        actions={[
          {
            title: <IntlMessages id="popup.changePassword" />,
            onClick: () => authenticationService.changePassword(),
            icon: "resetPassword",
          },
          {
            title: <IntlMessages id="popup.logout" />,
            onClick: () => authenticationService.signOut(),
            icon: "logout",
          },
        ]}
        align="end"
      >
        <Icons type="moreVert" size="xl" />
      </ActionsMenu>
    </div>
  );
}

export default UserInfo;
