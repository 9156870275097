import React, { useEffect } from "react";
import { useSeedContext } from "context/SeedContext";
import { languageData } from "util/localeHelper";
import Select from "components/Common/Select";

function LanguageSelector() {
  const { locale, switchLanguage } = useSeedContext();

  useEffect(() => {
    if (locale.locale) {
      localStorage.setItem("TB_ADMIN_LANGUAGE", locale.locale);
    }
  }, [locale]);

  return (
    <div className="flex flex-shrink-0">
      <Select
        options={languageData}
        getOptionLabel={(v) => (
          <div className="flex items-center gap-2">
            <img src={`/assets/images/${v.icon}.svg`} alt={v.icon} className="h-[18px]" />
            <div className="typo-14-500">{v.name}</div>
          </div>
        )}
        getOptionValue={(v) => v.locale}
        value={locale}
        onChange={(v) => switchLanguage(v)}
      />
    </div>
  );
}

export default LanguageSelector;

LanguageSelector.propTypes = {};

LanguageSelector.defaultProps = {};
