import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MenuItem from "../MenuItem";
import styles from "./SidebarItem.module.scss";

function SidebarItem(props) {
  const { item, handleRedirect, pathname } = props;
  const [showList, setShowList] = useState(item?.subMenu?.find((i) => i.link === pathname) || false);
  const isSubMenu = item.subMenu && item.subMenu?.length > 0 && item.subMenu?.filter((i) => !i.hide)?.length > 0;

  return (
    <div>
      <MenuItem
        isSelected={item.link === pathname}
        showList={!!showList}
        isSubMenu={isSubMenu}
        setShowList={setShowList}
        onClick={() => handleRedirect(item.link)}
        iconType={item.icon}
        title={item?.title}
        // comment
      />
      {showList && isSubMenu && item.subMenu && (
        <div className={classNames(styles.sidebarItems, styles.moveToDown)}>
          {item.subMenu.map((subItem, index) => {
            if (subItem.hide) return null;
            return (
              <MenuItem
                key={index}
                isSelected={subItem.link === pathname}
                showList={false}
                isSubMenu={false}
                onClick={() => handleRedirect(subItem.link)}
                iconType={subItem.icon}
                title={subItem?.title}
                isSubComponent
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SidebarItem;

SidebarItem.propTypes = {
  item: PropTypes.array,
  handleRedirect: PropTypes.func,
  pathname: PropTypes.string,
};

SidebarItem.defaultProps = {
  item: [],
  pathname: "",
  handleRedirect: () => {},
};
