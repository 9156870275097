import React, { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import AuthServices, { h7Application } from "utils-auth/authService";
import { tokenRequest } from "utils-auth/AuthConfigAAD";
import MasterButton from "components/Common/MasterButton";
import { useRouter } from "context/RouteContext";
import { useAuthContext } from "context/AuthProvider";

const SignIn = () => {
  const router = useRouter();
  const { setIsAuthenticated, setRole, setName, setUserId } = useAuthContext();

  const [isLoginDone, setIsLoginDone] = useState(false);

  const handleSignIn = async () => {
    setIsLoginDone(true);
    await AuthServices.logIn();
  };

  useEffect(() => {
    const init = async () => {
      const token = localStorage.getItem("access_token");
      setIsLoginDone(true);

      if (!token) {
        try {
          const response = await h7Application.handleRedirectPromise();

          if (response != null) {
            const res = await AuthServices.getTokenRedirect(tokenRequest, response.account.homeAccountId);

            if (res) {
              localStorage.setItem("access_token", res.accessToken);
              localStorage.setItem("user_id", res.idTokenClaims.sub);

              setIsAuthenticated(true);
              setRole(res.idTokenClaims.userRoles);
              setName(res.idTokenClaims.name);
              setUserId(res.idTokenClaims.sub);

              setIsLoginDone(false);
              router.push("/tenants-list");
            }
          }
        } catch (error) {
          console.log(error);
        }
      }

      setIsLoginDone(false);
    };

    init();
  }, []);

  return (
    <div className="signIn-wrapper">
      <div className="image-1">
        <img src="/assets/images/1.jpeg" />
      </div>
      <div className="image-2">
        <img src="/assets/images/2.jpeg" />
      </div>
      <div className="image-3">
        <img src="/assets/images/3.jpeg" />
      </div>
      <div className="image-4">
        <img src="/assets/images/4.jpg" />
      </div>
      <div className="image-5">
        <img src="/assets/images/5.jpg" />
      </div>
      <div className="image-6">
        <img src="/assets/images/6.jpg" />
      </div>
      <div className="image-7">
        <img src="/assets/images/7.jpg" />
      </div>
      <div className="image-8">
        <img src="/assets/images/8.jpg" />
      </div>
      <div className="image-9">
        <img src="/assets/images/9.jpg" />
      </div>
      <div className="image-10">
        <img src="/assets/images/10.jpeg" />
      </div>
      <div className="image-11">
        <img src="/assets/images/11.jpg" />
      </div>
      <div className="image-12">
        <img src="/assets/images/12.jpg" />
      </div>
      <div className="login-box pt-20 pb-12 px-16 md:px-32">
        <MasterButton className="loginBtn" buttonText={<IntlMessages id="appModule.login" />} isLoading={isLoginDone} onClick={handleSignIn} />
      </div>
    </div>
  );
};

export default SignIn;
